@media (max-width: $sm) {
    .offer-slider {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;

        &__next,
        &__prev {
            width: 37px;
            height: 37px;
            background: $nude url('/app/assets/img/arrow.svg') no-repeat center;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            opacity: 0;
            transition: $transition;

            &.is-visible {
                opacity: .6;
            }
        }

        &__next {
            right: 10px;
            transform: translateY(-50%);
        }

        &__prev {
            left: 10px;
            transform: translateY(-50%) scale(-1,1);

        }
    }
}
