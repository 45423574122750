[am-section="contacts"] {
    min-height: 651px;
    background: #e1e1e1;
    position: relative;
}

[am-container="contacts"] {
    position: relative;
}

[am-title="contacts"] {
    margin-bottom: 32px;
    font-size: 32px;
}

[am-block="contacts"] {
    background: #fff;
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.15);
    width: 353px;
    padding: 40px 42px 45px 46px;
    position: absolute;
    right: 21px;
    top: 124px;
    z-index: 5;
}

[am-city-change="contacts"] {
    position: absolute;
    top: 0px;
    left: -355px;
    width: 100%;
    height: 105%;
    background: #fff;
    padding: 40px 76px;
    overflow: hidden;
    transition: $transition;
    opacity: 0;
    pointer-events: none;

    &.is-active {
        left: 0px;
        opacity: 1;
        pointer-events: auto;
    }
}

[am-title-sm="contacts"] {
    font-size: 24px;
}

[am-inner="contacts"] {
    position: relative;

    [am-back] {
        content: '';
        display: block;
        width: 25px;
        height: 19px;
        background: url("/app/assets/img/icons/arrow_back.svg") no-repeat center;
        position: absolute;
        left: -40px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

[am-list="contacts"] {
    margin-top: 20px;
    padding-bottom: 40px;
}

[am-item="contacts"] {
    cursor: pointer;
    transition: $transition;
    display: flex;

    &:hover {
        color: $red;
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:after {
        content: '';
        display: block;
    }

    &.is-active {
        &:after {
            margin-left: 10px;
            width: 21px;
            height: 15px;
            background: url("/app/assets/img/icons/check_red.svg") no-repeat center;
        }
    }
}

[am-address="contacts"] {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: $transition;
    margin-bottom: 7px;

    &:before {
        content: '';
        display: block;
        margin-right: 8px;
        @include svg-baloon_red();
        @include svg-baloon_red-dims();
    }
}

[am-change-city="contacts"] {
    color: #BE9A54;
    border-bottom: 1px dashed #BE9A54;
    transition: $transition;
    cursor: pointer;
    margin-bottom: 6px;
    margin-left: 23px;
    display: inline-block;

    &:hover {
        color: $red;
        border-bottom: 1px dashed $red;
    }
}

[am-phone="contacts"] {
    color: #1E1E1E;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:before {
        content: '';
        display: block;
        margin-right: 5px;
        @include svg-phone_red();
        @include svg-phone_red-dims();
    }

    &:hover {
        color: $red;
    }

    a {
        color: #1e1e1e;
    }
}

[am-mail="contacts"] {
    color: #1E1E1E;
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    &:before {
        content: '';
        display: block;
        margin-right: 8px;
        @include svg-mail();
        @include svg-mail-dims();
    }

    &:hover {
        color: $red;
    }
}

[am-show="contacts"] {
    color: $red;
    display: none;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-section="contacts"] {
        min-height: 631px;
    }
}

@media (max-width: $sm) {
    [am-map] {
        display: none;
    }

    [am-address="contacts"] {
        margin-bottom: 4px;
    }

    [am-section="contacts"] {
        min-height: auto;
    }

    [am-title="contacts"] {
        font-size: 24px;
        margin-bottom: 22px;
    }

    [am-container="contacts"] {
        padding: 0;
    }

    [am-block="contacts"] {
        padding: 40px 30px 40px 28px;
        position: static;
        max-width: 100%;
        width: 100%;
    }

    [am-address="contacts"] {
        align-items: flex-start;
        margin-bottom: 3px;
        font-size: 14px;
    }

    [am-show="contacts"] {
        margin-left: 26px;
        margin-bottom: 9px;
        display: block;
        font-size: 14px;
        color: #BE9A54;
    }

    [am-change-city="contacts"] {
        margin-left: 25px;
        font-size: 14px;
        border: 0;

        &:hover {
            border: 0;
        }
    }

    [am-phone="contacts"] {
        &[am-link="whatsapp"] {
            margin-bottom: 9px;
            margin-top: 5px;
            margin-left: 0;
            font-size: 14px;
        }
    }

    [am-mail="contacts"] {
        font-size: 14px;
        margin-bottom: 34px;
    }

    [am-inner="contacts"] {
        [am-close] {
            @include svg-cross();
            @include svg-cross-dims();
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    [am-city-change="contacts"] {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

}
