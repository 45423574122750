[am-map] {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

[am-place=map] {
    width: 100%;
    height: 100%;
}
