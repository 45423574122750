[am-change="minus"] {
    color: $dark_red;
    display: flex;
    align-items: center;
    margin-left: 10px;

    &:before {
        content: '';
        display: block;
        margin-top: -3px;
        @include svg-triangle_red();
        @include svg-triangle_red-dims();
    }
}

[am-change="zero"] {
    color: $grey;
    display: flex;
    align-items: center;
    margin-left: 8px;

    &:before {
        content: '';
        display: block;
        margin-right: 4px;
        margin-top: -2px;
        @include svg-square();
        @include svg-square-dims();
    }
}

[am-change="plus"] {
    color: $green;
    display: flex;
    align-items: center;
    margin-left: 18px;
    margin-top: 1px;

    &:before {
        content: '';
        display: block;
        margin-right: 3px;
        @include svg-triangle_green();
        @include svg-triangle_green-dims();
    }
}