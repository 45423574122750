*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

body {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    overflow-x: hidden;
    font-size: 16px;
    color: #1E1E1E;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    transition: $transition;
}

h2 {
    font-size: 34px;
    line-height: 1.21;
    text-transform: uppercase;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
}

p {
    line-height: 1.5;
}

select {
    border: none;
}

select:active,
select:focus {
    outline: none;
}

select:focus > option {
    outline: 0;
}

input:focus,
textarea:focus {
    border: 1px solid #787878;
    outline: none;
}

[am-padding="none"] {
    padding: 0 !important;
}

@include hover-bg('platinum', $platinum_gradient);
@include hover-bg('gold', $gold_gradient);
@include hover-bg('silver', $silver_gradient);

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $sm) {
    h2 {
        font-size: 24px;
    }
}