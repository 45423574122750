@media (max-width: $sm) {
    .about-slider {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        &__wrapper {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: nowrap;
        }

        .about-slide {
            transition: $transition;
            // opacity: 0;
            min-width: 100%;

            &__active {
                // opacity: 1;
            }
        }

        &__pagination {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 9px;
        }

        &__bullet {
            width: 8px;
            height: 8px;
            border: 1px solid #B8B3A6;
            border-radius: 100%;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 7px;
            }

            &_active {
                border: 1px solid #BE9A54;
                background: #BE9A54;
            }
        }
    }
}