[am-block="burger"] {
    cursor: pointer;
    display: none;
    position: relative;
    z-index: 15;
    width: 26px;
    height: 22px;
    justify-content: space-between;
    flex-direction: column;

    &.is-active {
        [am-line="burger"] {
            transform-origin: 100%;
            transition: $transition;

            &:first-child {
                transform: translateY(-9%) rotate(-45deg);
            }

            &:nth-child(2) {
                transform: translateY(-50%) rotate(45deg);
            }

            &:last-child {
                display: none;
            }
        }
    }
}

[am-line="burger"] {
    width: 26px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 1px;
}

[am-cities="burger"] {
    background: $dark_brown;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: inherit;
    transition: $transition;
    z-index: 6;
    transform: translateX(-100%);

    &.is-active {
        transform: translateX(0);
    }
}

[am-cities-head="burger"] {
    font-size: 24px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 106px 0px;
    font-weight: 700;

    &:after {
        content: '';
        display: block;
        width: 26px;
        height: 26px;
        pointer-events: none;
    }
}

[am-cities-close="burger"] {
    content: '';
    display: block;
    @include svg-back();
    @include svg-back-dims();
}

[am-cities-list="burger"] {
    padding: 42px 106px;
    overflow: auto;
    max-height: inherit;
}

[am-cities-item="burger"] {
    font-size: 18px;
    display: flex;
    align-items: center;

    &.is-active {
        &:after {
            content: '';
            display: block;
            margin-left: 10px;
            @include svg-check();
            @include svg-check-dims();
        }
    }

    &:not(:last-child) {
        margin-bottom: 25px;
    }
}

[am-section="burger"] {
    display: none;
    background: $dark_brown;
    padding: 0 106px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: $transition;
    z-index: 5;

    &.is-active {
        padding: 80px 106px 77px;
        max-height: 460px;
    }

    [am-phone] {
        display: flex;
        flex-direction: row;

        [am-whatsapp] {
            margin-left: 10px;
        }
    }
}

[am-menu="burger"] {
    padding-bottom: 36px;
    max-width: 254px;
    border-bottom: 1px solid $red;
    margin-bottom: 37px;
}

[am-item="burger"] {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

[am-link="burger"] {
    color: #FDFDFD;
    font-size: 18px;
}


[am-city="burger"] {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: $transition;

    &:before {
        content: '';
        display: block;
        margin-right: 8px;
        @include svg-baloon_red();
        @include svg-baloon_red-dims();
    }

    &:after {
        content: '';
        display: block;
        margin-left: 8px;
        @include svg-angle_grey();
        @include svg-angle_grey-dims();
    }
}

[am-phone="burger"] {
    color: #fff;
    display: flex;
    align-items: center;

    &[am-link="whatsapp"] {

        [am-icon="whatsapp_inverted"] {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    &:before {
        content: '';
        display: block;
        margin-right: 5px;
        @include svg-phone_red();
        @include svg-phone_red-dims();
    }

    &:hover {
        color: $red;
    }
}

[am-mail="burger"] {
    color: #fff;
    display: flex;
    align-items: center;

    &:before {
        content: '';
        display: block;
        margin-right: 5px;
        margin-left: 2px;
        @include svg-mail();
        @include svg-mail-dims();
    }

    &:hover {
        color: $red;
    }
}


[am-btn="burger"] {
    border: 1px solid #B8B3A6;
    border-radius: 3px;
    background: transparent;
    color: #fff;
    width: 164px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $transition;
    margin-top: 30px;

    &:hover {
        background: #BE9A54;
        border: 1px solid #BE9A54;
    }

    &:active {
        background: #997735;
        border: 1px solid #997735;
    }
}

[am-inner="burger"] {
    margin-bottom: 16px;

}

[am-operating="burger"] {
    margin-left: 27px;
    font-weight: 500;
    font-size: 14px;
    color: #8C8C8C;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $md) {
    [am-block="burger"] {
        display: flex;
    }

    [am-section="burger"] {
        display: block;
    }

    [am-btn="burger"] {
        display: none;
    }

}

@media (max-width: $sm) {

    [am-section="burger"] {
        padding: 0 36px;

        &.is-active {
            padding: 50px 36px;
        }
    }

    [am-city="burger"] {
        &:before {
            min-width: 18px;
        }
    }

    [am-cities-head="burger"] {
        padding: 27px 32px 0px 35px;
    }

    [am-cities-list="burger"] {
        padding: 42px 36px;
    }


    [am-btn="burger"] {
        display: flex;
    }
}
