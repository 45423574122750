[am-color] {
    transition: $transition;
}

[am-color="platinum"] {
    background: $platinum_gradient;
}

[am-color="silver"] {
    background: $silver_gradient;
}

[am-color="gold"] {
    background: $gold_gradient;
}