[am-container] {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0px 20px;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-container] {
        max-width: 937px;
    }
}

@media (max-width: $md) {
    [am-container] {
        max-width: 660px;
    }
}
@media (max-width: $sm) {
    [am-container] {
        max-width: 100%;
        padding: 0px 30px;
    }
}

