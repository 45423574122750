@mixin svg-about_icon_five {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 61.71875% 0 no-repeat;
}

@mixin svg-about_icon_five-dims {
    width: 98px;
    height: 101px;
}

@mixin svg-about_icon_four {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 0 100% no-repeat;
}

@mixin svg-about_icon_four-dims {
    width: 98px;
    height: 100px;
}

@mixin svg-about_icon_one {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 0 54.95495495495496% no-repeat;
}

@mixin svg-about_icon_one-dims {
    width: 98px;
    height: 101px;
}

@mixin svg-about_icon_six {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 38.28125% 54.7085201793722% no-repeat;
}

@mixin svg-about_icon_six-dims {
    width: 98px;
    height: 100px;
}

@mixin svg-about_icon_three {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 100% 0 no-repeat;
}

@mixin svg-about_icon_three-dims {
    width: 98px;
    height: 100px;
}

@mixin svg-about_icon_two {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 100% 44.84304932735426% no-repeat;
}

@mixin svg-about_icon_two-dims {
    width: 98px;
    height: 100px;
}

@mixin svg-angle_grey {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 55.55555555555556% 33.114754098360656% no-repeat;
}

@mixin svg-angle_grey-dims {
    width: 12px;
    height: 18px;
}

@mixin svg-arrow_black {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 84.90566037735849% 77.97202797202797% no-repeat;
}

@mixin svg-arrow_black-dims {
    width: 36px;
    height: 37px;
}

@mixin svg-arrow_black_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 30.529595015576323% 93.77162629757785% no-repeat;
}

@mixin svg-arrow_black_mob-dims {
    width: 33px;
    height: 34px;
}

@mixin svg-arrow_grey {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 73.41772151898734% 79.07801418439716% no-repeat;
}

@mixin svg-arrow_grey-dims {
    width: 38px;
    height: 41px;
}

@mixin svg-arrow_grey_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 40.62015503875969% 93.77162629757785% no-repeat;
}

@mixin svg-arrow_grey_mob-dims {
    width: 31.5px;
    height: 34px;
}

@mixin svg-arrow_red {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 96.22641509433963% 77.97202797202797% no-repeat;
}

@mixin svg-arrow_red-dims {
    width: 36px;
    height: 37px;
}

@mixin svg-arrow_red_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 61.059190031152646% 64.70588235294117% no-repeat;
}

@mixin svg-arrow_red_mob-dims {
    width: 33px;
    height: 34px;
}

@mixin svg-arrow_white {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 61.392405063291136% 79.07801418439716% no-repeat;
}

@mixin svg-arrow_white-dims {
    width: 38px;
    height: 41px;
}

@mixin svg-arrow_white_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 38.44961240310077% 30.103806228373703% no-repeat;
}

@mixin svg-arrow_white_mob-dims {
    width: 31.5px;
    height: 34px;
}

@mixin svg-back {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 79.01234567901234% 66.66666666666667% no-repeat;
}

@mixin svg-back-dims {
    width: 30px;
    height: 23px;
}

@mixin svg-baloon {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 51.4792899408284% 33.443708609271525% no-repeat;
}

@mixin svg-baloon-dims {
    width: 16px;
    height: 21px;
}

@mixin svg-baloon_map {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 64.05228758169935% 47.286821705426355% no-repeat;
}

@mixin svg-baloon_map-dims {
    width: 48px;
    height: 65px;
}

@mixin svg-baloon_red {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 46.74556213017752% 33.443708609271525% no-repeat;
}

@mixin svg-baloon_red-dims {
    width: 16px;
    height: 21px;
}

@mixin svg-check {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 87.1951219512195% 66.00660066006601% no-repeat;
}

@mixin svg-check-dims {
    width: 26px;
    height: 20px;
}

@mixin svg-close {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 47.712418300653596% 81.0909090909091% no-repeat;
}

@mixin svg-close-dims {
    width: 48px;
    height: 48px;
}

@mixin svg-close_hover {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 32.02614379084967% 81.0909090909091% no-repeat;
}

@mixin svg-close_hover-dims {
    width: 48px;
    height: 48px;
}

@mixin svg-cross {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 59.72644376899696% 90.63545150501672% no-repeat;
}

@mixin svg-cross-dims {
    width: 25px;
    height: 24px;
}

@mixin svg-logo {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 0 15.714285714285714% no-repeat;
}

@mixin svg-logo-dims {
    width: 158px;
    height: 43px;
}

@mixin svg-logo_footer {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 0 0 no-repeat;
}

@mixin svg-logo_footer-dims {
    width: 158px;
    height: 44px;
}

@mixin svg-logo_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 0 30.208333333333332% no-repeat;
}

@mixin svg-logo_mob-dims {
    width: 124px;
    height: 35px;
}

@mixin svg-logo_whatsapp {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 50.78125% 93.77162629757785% no-repeat;
}

@mixin svg-logo_whatsapp-dims {
    width: 34px;
    height: 34px;
}

@mixin svg-mail {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 93.41317365269461% 65.14657980456026% no-repeat;
}

@mixin svg-mail-dims {
    width: 20px;
    height: 16px;
}

@mixin svg-phone {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 67.12121212121212% 90.63545150501672% no-repeat;
}

@mixin svg-phone-dims {
    width: 24px;
    height: 24px;
}

@mixin svg-phone_mob {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 69.39393939393939% 62.96296296296296% no-repeat;
}

@mixin svg-phone_mob-dims {
    width: 24px;
    height: 26px;
}

@mixin svg-phone_red {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 74.39393939393939% 90.63545150501672% no-repeat;
}

@mixin svg-phone_red-dims {
    width: 24px;
    height: 24px;
}

@mixin svg-square {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 81.66666666666667% 90.63545150501672% no-repeat;
}

@mixin svg-square-dims {
    width: 24px;
    height: 24px;
}

@mixin svg-triangle_green {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 98.80952380952381% 64.93506493506493% no-repeat;
}

@mixin svg-triangle_green-dims {
    width: 18px;
    height: 15px;
}

@mixin svg-triangle_red {
    background: url("/app/assets/img/view/svg/sprite.view.svg") 88.93939393939394% 90.63545150501672% no-repeat;
}

@mixin svg-triangle_red-dims {
    width: 24px;
    height: 24px;
}
