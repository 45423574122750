[am-button] {
    border: none;
    background: transparent;
    cursor: pointer;
    transition: $transition;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0;
}

[am-button="white"] {
    color: #fff;
    transition: none;

    &:after {
        content: '';
        display: block;
        margin-left: 8px;
        @include svg-arrow_grey();
        @include svg-arrow_grey-dims();
    }

    &:hover {
        &:after {
            @include svg-arrow_white();
        }
    }
}

[am-button="black"] {
    transition: none;

    &:after {
        content: '';
        display: block;
        margin-left: 8px;
        @include svg-arrow_black();
        @include svg-arrow_black-dims();
    }

    &:hover {
        color: $red;

        &:after {
            @include svg-arrow_red();
        }
    }
}

[am-button="red"] {
    background: $red;
    border-radius: 3px;
    justify-content: center;
    width: 261px;
    height: 55px;
    color: #fff;
    font-size: 18px;

    &:hover {
        background: #9B0000;
    }
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $sm) {
    [am-button] {
        font-size: 14px;
    }

    [am-button="white"] {
        &:after {
            @include svg-arrow_grey_mob();
            @include svg-arrow_grey_mob-dims();
        }

        &:hover {
            &:after {
                @include svg-arrow_white_mob();
            }
        }
    }

    [am-button="black"] {
        &:after {
            @include svg-arrow_black_mob();
            @include svg-arrow_black_mob-dims();
        }

        &:hover {
            &:after {
                @include svg-arrow_red_mob();
            }
        }
    }

    [am-button="red"] {
        width: 100%;
        font-size: 18px;
    }


}
