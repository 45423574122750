[am-section="terms"] {
    padding: 65px 0;
}

[am-breadcrumbs="terms"] {
    color: $grey;
    margin-bottom: 22px;
    display: inline-block;
}

[am-title-sm="terms"] {
    padding-left: 30px;
    margin: 21px 0 3px;
    max-width: 70%;
    line-height: 1.5;
}

[am-list="terms"] {
    padding-left: 60px;
    padding-top: 11px;
}

[am-item="terms"] {
    color: #1e1e1e;
    margin-bottom: 15px;
    max-width: 64%;
    line-height: 1.5;
}

[am-date="terms"] {
    color: $grey;
    margin: 34px 0px 0px 30px;
}


// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $md) {
    [am-item="terms"] {
        max-width: 100%;
    }

    [am-title-sm="terms"] {
        max-width: 90%;
        padding-left: 15px;
    }

    [am-list="terms"] {
        padding-left: 30px;
    }

    [am-date="terms"] {
        margin-left: 15px;
    }

}

@media (max-width: $sm) {
    [am-section="terms"] {
        padding: 42px 0;
    }

    [am-breadcrumbs="terms"] {
        font-size: 14px;
    }

    [am-title-sm="terms"] {
        font-size: 14px;
        max-width: 100%;
        padding-left: 0;
    }

    [am-list="terms"] {
        padding-left: 20px;
        padding-top: 13px;
    }

    [am-item="terms"] {
        font-size: 14px;
    }

    [am-date="terms"] {
        font-size: 14px;
        margin-left: 0;
    }

}