@mixin font-face($font-family, $url, $weight: 400, $style: normal) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('#{$url}.eot');
    src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
    url('#{$url}.woff2') format('woff2'),
    url('#{$url}.woff') format('woff'),
    url('#{$url}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}

@mixin hover-bg($elem, $color) {
    [am-hover="#{$elem}"] {
        transition: $transition;

        &.is-active, &:hover {
            background: $color;
        }
    }
}

@mixin svg-logo_whatsapp_inverted {
    background: url("/app/assets/img/view/svg/sprite.view-8161c806.svg") 14.253393665158372% 89.47368421052632% no-repeat;
}

@mixin svg-logo_whatsapp_inverted-dims {
    width: 34px;
    height: 34px;
}
