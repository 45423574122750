@media (max-width: $md) {
    .no_office-slider {
        width: 100%;
        height: 100%;
        box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.15);
        background: #fff;
        overflow: hidden;
        margin-top: 40px;

        .no_office-slide {
            transition: $transition;
            // opacity: 0;
            min-width: 100%;

            &__active {
                // opacity: 1;
            }
        }
    }
}

@media (max-width: $sm) {
    .no_office-slider {
        margin-top: 25px;
    }
}