[am-section="company"] {
    padding: 90px 0;
}

[am-container="company"] {
    display: flex;
}

[am-block="company"] {
    max-width: 42%;

    &:last-child {
        margin-left: 101px;
        margin-top: 73px;
        max-width: 35%;
    }
}

[am-title="company"] {
    margin-bottom: 31px;
}

[am-text="company"] {
    line-height: 1.5;
    margin: 0;
}

[am-mail="company"] {
    color: $gold;

    &:hover {
        opacity: .8;
    }
}

[am-phone="company"] {
    color: #1E1E1E;
    font-size: 20px;
    font-weight: 700;
    display: block;
    margin: 9px 0 28px;

    &:hover {
        opacity: .8;
    }
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {

    [am-block="company"] {
        max-width: 50%;

        &:last-child {
            margin-left: 31px;
            max-width: 40%;
        }
    }

    [am-text="company"] {
        max-width: 400px;
    }

}

@media (max-width: $md) {
    [am-section="company"] {
        padding: 70px 0;
    }

    [am-container="company"] {
        flex-direction: column;
    }

    [am-block="company"] {
        max-width: 100%;

        &:last-child {
            margin-left: 0px;
            max-width: 100%;
            margin-top: 20px;

            [am-text="company"] {
                max-width: 380px
            }
        }
    }

    [am-text="company"] {
        max-width: 99%;
    }
}

@media (max-width: $sm) {
    [am-section="company"] {
        padding: 39px 0;
    }

    [am-title="company"] {
        margin-bottom: 15px;
    }

    [am-text="company"] {
        font-size: 14px;
    }

    [am-mail="company"] {
        font-size: 14px;
    }

    [am-block="company"] {

        &:last-child {
            margin-top: 15px;
        }
    }

    [am-phone="company"] {
        font-size: 18px;
        margin-bottom: 19px;
    }

}

