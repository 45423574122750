[am-section="no_office"] {
    background: $nude;
    padding: 90px 0;
}

[am-title="no_office"] {
    max-width: 490px;
}

[am-wrapper="no_office"] {
    background: #FFFFFF;
    margin-top: 40px;
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

[am-card="no_office"] {
    border: 1px solid #DCDCDC;
    padding: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 477px;
    transition: $transition;
    cursor: pointer;

    &:hover {
        background: $red;
        color: #fff;

        [am-num="no_office"] {
            background: #F1D4CC;
            border: 2px solid #F1D4CC;
            color: $red;
        }

        [am-link="no_office"] {
            color: #E7CFA2;

            &:hover {
                color: #BE9A54;
            }   
        }

        [am-btn="no_office"] {
            opacity: 1;
        }

    }
}

[am-num="no_office"] {
    border: 2px solid #BE9A54;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #BE9A54;
    transition: $transition;
}

[am-title-sm="no_office"] {
    margin: 20px 0 15px;
    transition: $transition;
}

[am-text="no_office"] {
    line-height: 1.25;
}

[am-list="no_office"] {
    counter-reset: num;
    padding-left: 20px;
    margin-bottom: 23px;

    &[am-indent="bottom"] {
        margin-bottom: 18px;

        [am-item="no_office"] {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}

[am-item="no_office"] {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 11px;
    }

    &:before {
        content: counter(num) '.';
        counter-increment: num;
        margin-right: 11px;
        min-width: 14px;
        display: block;
    }
}

[am-btn="no_office"] {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 283px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    opacity: 0;
    cursor: pointer;
    transition: $transition;

    &:hover {
        color: $red;
    }

    &:active {
        background: #FAF4E6;
    }
}

[am-link="no_office"] {
    color: #be9a54;
    border-bottom: 1px dashed #be9a54;
    transition: .3s linear;
    display: inline-block;
}

[am-slider-pagination="no_office"] {
    display: none;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-card="no_office"] {
        padding: 30px;
    }
}

@media (max-width: $md) {
    [am-section="no_office"] {
        padding: 65px 0 71px;
    }

    [am-wrapper="no_office"] {
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: stretch;
        background: transparent;
        box-shadow: none;
        margin-top: 0;
    }

    [am-num="no_office"] {
        display: none;
    }


    [am-card="no_office"] {
        background: #fff;
        border: none;
        min-height: 368px;
        padding-bottom: 40px;
        padding-top: 0;

        &:hover {
            background: #fff;
            color: #1e1e1e;

            [am-num="no_office"] {
                background: transparent;
                border: 2px solid #BE9A54;
                color: #BE9A54;
            }

            [am-link="no_office"] {
                color: #BE9A54;
            }

        }
    }

    [am-btn="no_office"] {
        opacity: 1;
        background: #B82800;
        color: #fff;

        &:hover {
            background: #9B0000;
            color: #fff;
        }

        &:active {
            background: #780000;
        }
    }

    [am-title-sm="no_office"] {
        margin-top: 0;

    }

    [am-slider-pagination="no_office"] {
        display: flex;
        padding: 29px 31px 20px;
        max-width: 464px;
        justify-content: space-between;

        [am-num="no_office"] {
            display: flex;
            cursor: pointer;
            position: relative;
            background: #fff;

            &:hover {
                background: #FAF4E6;
                border: 2px solid #ECE1CC;
            }

            &:not(:last-child) {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 2px);
                    transform: translateY(-50%);
                    width: 70px;
                    height: 2px;
                    background: #ECE1CC;
                }
            }

            &.is-active {
                background: #BE9A54;
                color: #fff;

                &:hover {
                    background: #BE9A54;
                    border: 2px solid #BE9A54;
                }
            }
        }
    }

}

@media (max-width: $sm) {

    [am-section="no_office"] {
        padding: 40px 0;
    }

    [am-container="no_office"] {
        padding: 0;
    }

    [am-title="no_office"] {
        padding: 0px 30px;
    }

    [am-slider-pagination="no_office"] {
        padding: 30px 32px 20px 27px;
    }

    [am-title-sm="no_office"] {
        margin-bottom: 16px;
    }

    [am-text="no_office"] {
        font-size: 14px;
        line-height: 1.5;
    }

    [am-list="no_office"] {
        font-size: 14px;
        margin-bottom: 10px;
    }

    [am-btn="no_office"] {
        width: 100%;
        margin-top: 3px;
    }

    [am-link="no_office"] {
        font-size: 14px;
    }

    [am-card="no_office"] {
        padding-bottom: 30px;
    }

}
