// Transition
$transition: .3s linear;

// Colors
$red: #B82800;
$dark_red: #C20000;
$dark_grey: #1E1E1E;
$grey: #A0A0A0;
$green: #00940F;
$gold: #D2B378;
$nude: #FAF4E6;
$dark_brown: #1E1514;

// Gradient
$background_gradient: linear-gradient(280.73deg, #D2B378 0%, #F4E8CA 99.65%);
$platinum_gradient: linear-gradient(277.36deg, #878B85 0%, #C4C4C4 107.91%);
$gold_gradient: linear-gradient(274.91deg, #B48443 0%, #C9A755 96.89%);
$silver_gradient: linear-gradient(93.8deg, #A4A9AD 0%, #76787E 103.52%);

// Breakpoints
$lg: 1150px;
$md: 940px;
$sm: 670px;
