[am-section="about"] {
    padding: 100px 0px 43px;
    background: url('/app/assets/img/chain.jpg') no-repeat top right / 67%;
}

[am-title="about"] {
    max-width: 50%;
    margin-bottom: 32px;
}

[am-text="about"] {
    max-width: 60%;
    margin-bottom: 26px;
}

[am-block="about"] {
    display: flex;
    justify-content: space-between;
}

[am-wrapper="about"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 795px;
    margin-top: 46px;
}

[am-itme="about"] {
    max-width: 237px;
    line-height: 1.5;
    margin-bottom: 46px;

    &:before {
        content: '';
        display: block;
        margin-bottom: 19px;
    }

    &[am-icon="years"] {
        &:before {
            @include svg-about_icon_one();
            @include svg-about_icon_one-dims();
        }
    }

    &[am-icon="guarantee"] {
        &:before {
            @include svg-about_icon_two();
            @include svg-about_icon_two-dims();
        }
    }

    &[am-icon="benefit"] {
        &:before {
            @include svg-about_icon_three();
            @include svg-about_icon_three-dims();
        }
    }

    &[am-icon="bonus"] {
        &:before {
            @include svg-about_icon_four();
            @include svg-about_icon_four-dims();
        }
    }

    &[am-icon="geography"] {
        &:before {
            @include svg-about_icon_five();
            @include svg-about_icon_five-dims();
        }
    }

    &[am-icon="cash"] {
        &:before {
            @include svg-about_icon_six();
            @include svg-about_icon_six-dims();
        }
    }
}

[am-sub-text="about"] {
    color: #8C8C8C;
    line-height: 1.5;
    width: 260px;
    margin-top: 50px;
    margin-left: 40px;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-section="about"] {
        padding: 80px 0px 43px;
    }

    [am-title="about"] {
        max-width: 90%;
    }

    [am-text="about"] {
        max-width: 73%;
    }

    [am-sub-text="about"] {
        display: none;
    }

    [am-wrapper="about"] {
        max-width: 95%;
    }

}

@media (max-width: $md) {
    [am-section="about"] {
        padding: 62px 0px 33px;
    }

    [am-title="about"] {
        margin-bottom: 30px;
    }

    [am-text="about"] {
        max-width: 92%;
    }

    [am-itme="about"] {
        font-size: 14px;
        max-width: 195px;
        margin-bottom: 36px;
    }

    [am-wrapper="about"] {
        max-width: 100%;
        margin-top: 36px;
    }

}

@media (max-width: $sm) {
    [am-section="about"] {
        padding: 42px 0px 32px;
    }

    [am-title="about"] {
        margin-bottom: 17px;
        max-width: 100%;
    }

    [am-wrapper="about"] {
        margin-top: 26px;
    }

    [am-itme="about"] {

        &:before {
            margin-bottom: 13px;
        }
    }

    [am-text="about"] {
        max-width: 95%;
        font-size: 14px;
        margin-bottom: 16px;
    }
}
