[am-section="footer"] {
    padding: 55px 0;
}

[am-container="footer"] {
    display: flex;
    align-items: flex-start;
}

[am-logo="footer"] {
    @include svg-logo_footer();
    @include svg-logo_footer-dims();
    min-width: 158px;
}

[am-menu="footer"] {
    display: flex;
    margin-left: 122px;
    margin-top: 12px;
    flex-grow: 1;
    max-height: 110px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

[am-item="footer"] {
    &:not(:last-child) {
        margin-right: 31px;
        margin-bottom: 17px;
    }

    &[am-link="whatsapp"] {
        display: flex;
        flex-direction: row;
        margin-bottom: 21px;
    }

}

[am-link="footer"] {
    color: #1E1E1E;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:hover {
        color: $red;
    }
}

[am-link="whatsapp"] {
    a {
        margin-top: -7px;
    }

    [am-icon="whatsapp"] {
        margin-left: 10px;
    }
}

[am-icon="whatsapp"] {
    @include svg-logo_whatsapp();
    @include svg-logo_whatsapp-dims();
    display: inline-block;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {

    [am-section="footer"] {
        padding: 37px 0 20px;
    }

    [am-menu="footer"] {
        margin-left: 72px;
        margin-top: 7px;
    }

    [am-item="footer"] {
        &:not(:last-child) {
            margin-right: 74px;
        }
    }

}

@media (max-width: $md) {

    [am-section="footer"] {
        padding: 38px 0 29px;
    }

    [am-container="footer"] {

    }

    [am-menu="footer"] {
        margin-bottom: 0;
        margin-top: 10px;
        margin-left: 55px;
        max-height: 230px;
    }

    [am-item="footer"] {
        max-width: 100px;


        &[am-link="whatsapp"] {
            max-width: 100%;
        }

        &:not(:last-child) {
            margin-right: 55px;
            margin-bottom: 17px;
        }
    }

    [am-link="footer"] {
        white-space: normal;
    }

}

@media (max-width: $sm) {
    [am-container="footer"] {
        flex-direction: column;
    }

    [am-menu="footer"] {
        margin-left: 0;
        margin-top: 23px;
        margin-bottom: 0;
        max-height: none;
        flex-direction: column;
    }

    [am-item="footer"] {
        max-width: 90%;
    }

    [am-link="whatsapp"] {
        margin-top: 13px;
        margin-left: 0;
    }

    [am-item="footer"] {
        &[am-link="whatsapp"] {
            margin-bottom: 0;

            a {
                margin-top: -4px;
            }
        }
    }
}
