[am-wrapper="header"] {
    background: $dark_grey url('/app/assets/img/bg.jpg') no-repeat center / cover;
    color: #fff;
    position: relative;
    z-index: 10;
}

[am-video="header"] {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: 1200px;
}

[am-section="header"] {
    padding-top: 23px;
    position: relative;
    z-index: 2;
}

[am-container="header"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

[am-logo="header"] {
    @include svg-logo();
    @include svg-logo-dims();
}

[am-menu="header"] {
    display: flex;
    margin-left: 22px;
}

[am-item="header"] {
    &:not(:last-child) {
        margin-right: 41px;
    }
}

[am-inner="header"] {
    display: flex;
    margin-left: 16px;
}

[am-link="header"] {
    color: #fff;

    &:hover {
        color: $gold;
    }
}

[am-city="header"] {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 132px;
    transition: $transition;
    padding: 11px 21px 11px 12px;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        margin-right: 8px;
        @include svg-baloon();
        @include svg-baloon-dims();
    }

    &.is-active {
        background: #412A20;

        [am-dropdown="header"] {
            max-height: inherit;
            padding: 5px 0 14px 36px;
            opacity: 1;
            padding: 14px 36px;
            min-width: 100%;
            width: initial;
        }
    }
}

[am-dropdown="header"] {
    position: absolute;
    opacity: 0;
    transition: opacity .3s linear;
    top: 100%;
    left: 0;
    max-height: 0px;
    overflow: hidden;
    padding: 0 0 0 36px;
    width: 100%;
    background: #412A20;

    span {
        display: block;
        padding: 11px 0px;
        white-space: nowrap;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:hover {
            color: $gold;
        }
    }

}

[am-phone="header"] {
    color: #fff;
    display: flex;
    align-items: center;

    // &:before {
    //     content: '';
    //     display: block;
    //     margin-right: 5px;
    //     @include svg-phone();
    //     @include svg-phone-dims();
    // }

    &:hover {
        color: $gold;
    }
}

[am-phone-icon="header"] {
    display: none;
}

[am-btn="header"] {
    border: 1px solid #B8B3A6;
    border-radius: 3px;
    background: transparent;
    color: #fff;
    width: 164px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background: #BE9A54;
        border: 1px solid #BE9A54;
    }

    &:active {
        background: #997735;
        border: 1px solid #997735;
    }
}

[am-icon-group=header]{display:none;}

[am-icon="whatsapp_inverted"] {
    @include svg-logo_whatsapp_inverted();
    @include svg-logo_whatsapp_inverted-dims();
    display: inline-block;
    margin-right: 10px;
}

[am-block="header"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    margin-top: 7px;
}

[am-operating="header"] {
    margin-left: 27px;
    font-weight: 500;
    font-size: 14px;
    color: #8C8C8C;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-menu="header"] {
        margin-left: 12px;
        margin-top: 6px;
    }

    [am-section="header"] {
        padding-top: 9px;
    }

    [am-item="header"] {
        &:not(:last-child) {
            margin-right: 28px;
        }
    }

    [am-inner="header"] {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        margin-top: -3px;
    }

    [am-city="header"] {
        margin-left: 8px;
        margin-top: 14px;
        margin-bottom: -8px;
    }

    [am-phone="header"] {
        margin-top: -6px;
    }

}

@media (max-width: $md) {
    [am-section="header"] {
        padding-top: 12px;
    }

    [am-menu="header"] {
        display: none;
    }

    [am-inner="header"] {
        margin-right: 19px;
    }

    [am-video="header"] {
        min-width: 1000px;
        z-index: -1;
    }

    [am-logo="header"] {
        @include svg-logo_mob();
        @include svg-logo_mob-dims();
    }

    [am-city="header"] {
        margin-top: 0;
    }

}

@media (max-width: $sm) {
    [am-inner="header"],
    [am-btn="header"] {
        display: none;
    }

    [am-icon-group=header]{display:flex;align-items:center;}

    [am-phone-icon="header"] {
        @include svg-phone_mob();
        @include svg-phone_mob-dims();
        margin-left: 20px;
        margin-right: 20px;
        display: block;
    }

    [am-video="header"] {
        min-width: 800px;
    }
}
