[am-block="modal"] {
    display: none;

    &.is-active {
        display: block;
    }
}

[am-overlay="modal"] {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(30, 21, 20, 0.75);
    z-index: 10;
}

[am-close="modal"] {
    @include  svg-close();
    @include  svg-close-dims();
    position: absolute;
    top: -20px;
    right: -24px;
    cursor: pointer;

    &:hover {
        @include  svg-close_hover();
    }
}

[am-content="modal"] {
    position: relative;
    background: #fff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    max-width: 532px;
    padding: 35px 35px 40px;
}

[am-title="modal"] {
    font-size: 24px;
    margin-bottom: 26px;
}

[am-wrapper="modal"] {
    display: flex;
}

[am-label="modal"] {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 1.63913e-08px;
    color: #3B3D40;

    &:first-child {
        margin-right: 12px;
    }
}

[am-input="modal"] {
    margin: 10px 0 20px;
    border: 1px solid #DCDFDF;
    border-radius: 5px;
    font-size: 14px;
    padding: 14px 10px 13px;
}

[am-textarea="modal"] {
    resize: none;
    width: 100%;
    height: 90px;
    margin: 10px 0 30px;
    border: 1px solid #DCDFDF;
    border-radius: 5px;
    font-size: 14px;
    padding: 14px 10px 13px;
}

[am-btn="modal"] {
    width: 100%;
    font-size: 18px;
}

[am-agree="modal"] {
    text-align: center;
    font-size: 12px;
    margin: 14px 0px 0px;
    color: #3B3D40;
}

[am-error="modal"] {
    color: $red;
    font-size: 14px;
    text-align: center;
    display: none;

    &.is-active {
        margin: 0px 0px 20px;
        display: block;
    }
}

[am-text="modal"] {
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1.63913e-08px;
    color: #1E1E1E;
}

[am-modal="thx"] {
    text-align: center;

    [am-title="modal"] {
        max-width: 250px;
        margin: 31px auto 15px;
    }

    [am-text="modal"] {
        max-width: 361px;
        margin: 0px auto;
    }
}

[am-modal="cargo"] {

    &[am-overlay="modal"] {
        overflow: auto;
    }

    &[am-content="modal"] {
        max-width: 815px;
        margin-top: 120px;
    }

    [am-text="modal"] {
        text-align: left;
        line-height: 1.5;
    }
}

[am-list="modal"] {
    margin-bottom: 10px;
    padding-left: 30px;
}

[am-item="modal"] {
    font-weight: 700;
    margin-bottom: 25px;
}

[am-sub-list="modal"] {
    margin-top: 20px;
    padding-left: 30px;
}

[am-sub-item="modal"] {
    font-weight: 500;
    margin-bottom: 20px;
}

[am-title-sm="modal"] {
    margin-bottom: 10px;
    margin-top: 40px;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {

    [am-modal="cargo"] {

        &[am-overlay="modal"] {
            overflow: auto;
            align-items: flex-start;
        }

        &[am-content="modal"] {
            margin-top: 0;
            border-radius: 0;
        }

        [am-text="modal"] {
            text-align: left;
            line-height: 1.5;
        }

        [am-close="modal"] {
            @include svg-cross();
            @include svg-cross-dims();
            top: 25px;
            right: 25px;

            &:hover {
                @include svg-cross();
            }
        }
    }
}

@media (max-width: $sm) {
    [am-content="modal"] {
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow-y: auto;
    }

    [am-wrapper="modal"] {
        flex-direction: column;
    }

    [am-textarea="modal"] {
        height: 150px;
    }

    [am-close="modal"] {
        @include svg-cross();
        @include svg-cross-dims();
        top: 25px;
        right: 25px;

        &:hover {
            @include svg-cross();
        }
    }

    [am-head="modal"] {
        background: #fff;
        padding: 27px 30px 0;
    }

    [am-list="modal"] {
        font-size: 14px;
        line-height: 1.5;
        padding-left: 9px;
    }

    [am-item="modal"] {
        margin-bottom: 19px;
    }

    [am-sub-list="modal"] {
        margin-top: 15px;
        padding-left: 20px;
    }

    [am-sub-item="modal"] {
        margin-bottom: 15px;
    }

    [am-modal="cargo"] {

        [am-close="modal"] {
            top: 35px;
            right: 15px;
        }

        [am-title="modal"] {
            margin-bottom: 22px;
        }

        [am-title-sm="modal"] {
            font-size: 18px;
            margin-top: 30px;
        }

        [am-text="modal"] {
            font-size: 14px;
        }
    }

}