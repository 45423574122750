[am-section="price"] {
    padding: 75px 0;
    background: $background_gradient;
}

[am-container="price"] {
    background: #FFFFFF;
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 60px 60px 82px;
    max-width: 1100px;
}

[am-info="price"] {
    max-width: 251px;
    margin-right: 63px;
    width: 100%;
}

[am-title-sm="price"] {
    font-weight: 700;
}

[am-text="price"] {
    margin: 20px 0px 28px;
    line-height: 1.3;
}

[am-phone="price"] {
    color: #1E1E1E;

    &:hover {
        opacity: .7;
    }
}

[am-list="price"] {
    width: 100%;
    min-height: 348px;

    &[am-mob] {
        display: none;
    }
}

[am-tabs-content="price"] {
    display: none;

    &.is-active {
        display: block;
    }
}

[am-item="price"] {
    padding: 14px 19px 13px;

    &:first-child {
        padding: 7px 19px 8px;
    }

    &:nth-child(2n+2) {
        background: #FAF4E6;
    }
}

[am-name="price"] {
    min-width: 116px;
    display: inline-block;
    margin-right: 49px;
}

[am-cost="price"] {
    min-width: 133px;
    display: inline-block;
    margin-right: 48px;
}

[am-tabs="price"] {
    display: flex;
    margin: 5px 0px 31px;
    font-size: 18px;
}

[am-tab="price"] {
    cursor: pointer;
    min-width: 112px;
    width: 112px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A0A0A0;
    border-radius: 3px;

    &[data-tab="platinum"] {
        min-width: 125px;
    }

    &:hover, &.is-active {
        border: 1px solid transparent;
        color: #fff;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }
}

[am-slider="price"] {
    overflow-x: hidden;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {

    [am-container="price"] {
        max-width: 897px;
    }

    [am-phone="price"] {
        display: block;
    }

    [am-info="price"] {
        max-width: 221px;
        margin-right: 23px;
    }

     [am-name="price"],
     [am-cost="price"] {
        margin-right: 30px;
    }
}

@media (max-width: $md) {
    [am-section="price"] {
        padding: 49px 0;
    }

    [am-container="price"] {
        max-width: 620px;
        padding: 42px 40px 40px;
        display: flex;
        flex-direction: column;
    }

    [am-phone="price"] {
        display: inline-block;
    }

    [am-info="price"] {
        max-width: 79%;
        margin-right: 0;
        margin-bottom: 41px;

        br {
            display: none;
        }
    }

    [am-tabs="price"] {
        margin: 0px 0px 20px;
    }

}

@media (max-width: $sm) {
    [am-section="price"] {
        padding: 0;
    }

    [am-container="price"] {
        padding: 40px 0 40px 30px;
    }

    [am-text="price"] {
        margin: 17px 0 16px;
    }

    [am-info="price"] {
        margin-bottom: 33px;
    }

    [am-list="price"] {
        margin-left: -30px;
        width: calc(100% + 30px);
        min-height: 672px;

        &[am-mob] {
            display: block;
        }

        &[am-desk] {
            display: none;
        }
    }

    [am-cell="price"] {
        margin-top: 6px;

        span {
            min-width: 181px;
            width: 60%;
            display: inline-block;
        }

        &:first-child {
            font-weight: 700;
            margin-top: 0;
        }
    }

    [am-item="price"] {
        padding: 14px 19px 13px 31px;
        background: #FAF4E6;

        &:first-child {
            padding: 16px 19px 14px 31px;
        }


        &:nth-child(2n+2) {
            background: #fff;
        }
    }

}