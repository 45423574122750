[am-section="offer"] {
    padding: 99px 0px 125px;
    position: relative;
    z-index: 1;
}

[am-container="offer"] {
    display: flex;
    justify-content: space-between;
}

[am-card="offer"] {
    text-align: center;
    padding: 41px;
    border-radius: 3px;
    cursor: pointer;
    transition: $transition;

    &:hover [am-color] {
        background: $dark_brown;
    }

    &:hover [am-btn="offer"] {
        opacity: 1;
    }
}

[am-name="offer"] {

    span {
        font-size: 18px;
        padding: 10px 0px;
        width: 112px;
        text-align: center;
        border-radius: 3px;
        display: inline-block;
        margin-right: 10px;

        &[am-color="platinum"] {
            width: 125px;
        }
    }
}

[am-price="offer"] {
    margin-top: 21px;
    font-size: 80px;
}

[am-btn="offer"] {
    margin: 65px auto 0;
    opacity: 0;
}

// ================================================
// ================== @Media ======================
// ================================================

@media (max-width: $lg) {
    [am-section="offer"] {
        padding: 56px 0px 102px;
    }

    [am-name="offer"] {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    [am-card="offer"] {
        width: 287px;
    }

    [am-price="offer"] {
        margin-top: 21px;
        font-size: 80px;
    }

    [am-btn="offer"] {
        margin: 53px auto 0;
        opacity: 0;
    }
}

@media (max-width: $md) {
    [am-section="offer"] {
        padding: 54px 0 98px;
        z-index: 0;
    }

    [am-card="offer"] {
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 3px;
        width: 193px;
        padding: 30px 21px 30px 26px;

    }

    [am-price="offer"] {
        font-size: 50px;
    }

    [am-btn="offer"] {
        opacity: 1;
        margin: 34px auto 0;
    }
}


@media (max-width: $sm) {
    [am-section="offer"] {
        padding: 30px 0 50px;
    }

    [am-name="offer"] {
        font-size: 14px;
    }

    [am-card="offer"] {
        min-width: 183px;
        padding: 28px 21px 25px 27px;
    }

    [am-name="offer"] {
        span {
            margin-bottom: 17px;
        }
    }

    [am-price="offer"] {
        margin-top: 17px;
    }

    [am-btn="offer"] {
        margin: 31px auto 0;
    }

}